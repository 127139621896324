import styled from "styled-components";
import { media } from "../../utils/styled/responsive";

export const Container = styled.div`
  display: grid;
  place-items: center;
  padding: 0 16px 120px;

  ${media.tablet} {
    padding: 0 48px 160px;
  }
`;

export const Heading = styled.div`
  display: grid;
  gap: 24px;
  place-items: center;
  text-align: center;
  padding: 160px 0 160px 0;
`;

export const List = styled.div`
  display: grid;
  place-content: center;
`;

export const ListItem = styled.div`
  border-top: 1px solid #1a1a1a;
  display: grid;
  gap: 24px;
  padding: 40px 0;

  ${media.tablet} {
    gap: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${media.desktop} {
    width: 64vw;
  }
`;
