import { graphql } from "gatsby";
import React from "react";
import FadeInOut from "../../components/Animations/FadeInOut/FadeInOut";
import Shader from "../../components/Shader/Shader";
import LineReveal from "../../components/Text/LineReveal/LineReveal";
import { Container, Heading, List, ListItem } from "./PremistesPage.styles";

function Premises({ data }) {
  const { title, slogan } = data.contentfulPremises;
  const premisesArray = data.allContentfulPremisesList.edges;

  return (
    <FadeInOut>
      <Shader />
      <Container>
        <Heading>
          <LineReveal renderAs="h1" text={title} />
          <LineReveal delay={0.4} text={slogan} />
        </Heading>
        <List>
          {premisesArray.map(({ node }) => {
            return (
              <ListItem key={node.name}>
                <a
                  href={node.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ maxWidth: 300 }}
                >
                  <LineReveal renderAs="h3" text={node.name} />
                </a>
                <LineReveal delay={0.2} renderAs="p" text={node.adress} />
              </ListItem>
            );
          })}
        </List>
      </Container>
    </FadeInOut>
  );
}

export default React.memo(Premises);

export const query = graphql`
  query Premises($slug: String, $locale: String) {
    contentfulPremises(url: { eq: $slug }) {
      slogan
      title
      url
    }
    allContentfulPremisesList(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          name
          adress
          url
          node_locale
        }
      }
    }
  }
`;
